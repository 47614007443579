@import url('https://fonts.googleapis.com/css2?family=Iceland&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html  {
    background-image: url('./assets/background.png');
    background-repeat: no-repeat;
    background-size: cover;
}
footer div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
footer img {
    width: 15%;
}
footer img:nth-of-type(1){
    margin-bottom: -15px;
}
footer img:nth-of-type(3){
    background-color: white;
    width: 8%;
}

@media (max-width: 1024px){
    footer div {
        justify-content: between;
        width: 80%;
        margin: 0 auto;
    }
    footer img {
        width: 50%;
        margin-top: 5%;
    }
    footer img:nth-of-type(3){
        width: 22%;
        margin-left: 13%;
    }
}